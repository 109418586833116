<template>
  <div class="upload_box">
    <LEFTNAV></LEFTNAV>
    <div class="box">
      <div class="box_part" v-bind:style="{ minHeight: Height - 40 + 'px' }">
        <div class="fil_view">
          <div class="title">
            <h2 style="text-align: center; font-size: 25px; line-height: 80px">
              writful 文件上传
            </h2>
          </div>
          <div class="prompts">
            <div class="prompt">
              <el-steps :active="3">
                <el-step title="上传文件" icon="el-icon-upload"></el-step>
                <el-step
                  title="进行检测"
                  icon="el-icon-data-analysis"
                ></el-step>
                <el-step title="生成报告" icon="el-icon-tickets"></el-step>
              </el-steps>
              <div class="note_fu">
                <div class="note_fu01">
                  <el-divider content-position="left">注意事项</el-divider>
                  
                  <div class="note">
                    <p>
                      <img src="../../assets/imgs/yellow.png" alt="" />
                      <span>每次最多上传1个文档。</span>
                    </p>
                    <p>
                      <img src="../../assets/imgs/yellow.png" alt="" />
                      <span
                        >文档上传仅限于支持doc 、docx 、 txt 格式文件。</span
                      >
                    </p>
                    <p>
                      <img src="../../assets/imgs/yellow.png" alt="" />
                      <span>PC端程序暂不支持该功能。</span>
                    </p>
                  </div>
                </div>
              </div>

              <!-- <img style="width: 100%" src="../../../assets/imgs/上传.png" alt="" /> -->
            </div>
          </div>

          <div class="upLoad">
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              name="file"
              :data="ruleForm"
              label-width="100px"
              class="demo-ruleForm"
              id="uploadForm"
            >
              <!-- <el-form-item label="文章标题" prop="title">
            <el-input v-model="ruleForm.title"></el-input>
          </el-form-item>
          <el-form-item label="文章作者" prop="author">
            <el-input v-model="ruleForm.author"></el-input>
          </el-form-item>
          <el-form-item label="检测类型" prop="task_type">
            <el-radio-group v-model="ruleForm.task_type">
              <el-radio :label="0">论文查重</el-radio>
              <el-radio :label="1">语法校对</el-radio>
            </el-radio-group>
          </el-form-item> -->
              <!-- <el-form-item label="文件上传" prop="file"> -->
              <div class="usage">当日剩余使用量：{{ usages }}</div>
              <div class="upDemo" v-if="isRouterAlive">
                <el-upload
                  class="upload-demo"
                  id="uploadForm"
                  drag
                  :action="actionUrl"
                  :headers="{ token: token }"
                  :before-upload="beforeUpload"
                  :on-preview="handlePreview"
                  :on-remove="handleRemove"
                  :on-change="on_change"
                  :before-remove="beforeRemove"
                  multiple
                  :limit="1"
                  :on-exceed="handleExceed"
                  :auto-upload="false"
                  :file-list="fileList"
                >
                  <div class="upStyle">
                    <div style="margin: 0 0 50px 50px">
                      <div style="">
                        <i
                          class="el-icon-upload"
                          style="
                            font-size: 67px;
                            color: #c0c4cc;
                            margin: 40px 0 16px;
                            line-height: 50px;
                          "
                        ></i>
                        <div class="el-upload__text">
                          <em style="font-style: normal">点击上传</em>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="el-upload__tip" slot="tip">只能上传txt/docx/pdf文件</div> -->
                </el-upload>
                <div class="upload_tip">
                  <img
                    class="upload_tip_img"
                    src="../../assets/imgs/tip.307c8b1.png"
                    alt=""
                  />
                  <div class="upload_tip_text">
                    <p class="upload_tip_p">每次最多上传1个文档。</p>

                    <p class="upload_tip_p">
                      文档上传仅限于支持doc 、docx 、 txt 格式文件。
                    </p>

                    <p class="upload_tip_p">
                      PC端程序暂不支持该功能。
                      <br />
                    </p>
                  </div>
                </div>
              </div>
              <!-- </el-form-item>
          <el-form-item> -->
              <div class="btn">
                <el-button
                  type="primary"
                  @click="submitForm()"
                  :loading="returns"
                  >提&emsp;交</el-button
                >
              </div>
              <!-- </el-form-item> -->
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LEFTNAV from "./component/leftNav.vue";
import $ from "jquery";
import { Message } from "element-ui";
import {
  detail_list,
  input_file,
  download,
  get_limit,
} from "@/Api/scholarcy_writeful01";
export default {
  data() {
    return {
      Height: 0,
      actionUrl: "https://scholary.newacademic.net/api/qingli/writ/input_file",
      token: localStorage.getItem("token"),
      ruleForm: {
        title: "",
        author: "",
        task_type: null,
        file: "",
      },
      rules: {
        // title: [{ required: true, message: "请输入文章标题", trigger: "blur" }],
        // author: [
        //   { required: true, message: "请输入文章作者", trigger: "blur" },
        // ],
        // task_type: [
        //   { required: true, message: "请选择检测类型", trigger: "change" },
        // ],
        file: [
          { required: true, message: "请选择文件上传", trigger: "change" },
        ],
      },
      fileList: [],
      checkAgain: false,
      detects: true,
      isRouterAlive: true,
      returns: false,
      usages: 0,
    };
  },
  mounted() {
    //动态设置内容高度 让footer始终居底   header+footer的高度是190
    // console.log(this.name)
    this.Height = document.documentElement.clientHeight - 140; //监听浏览器窗口变化
    window.onresize = () => {
      this.Height = document.documentElement.clientHeight - 140;
    };
    this.getUsage();
  },
  created() {},
  methods: {
    getUsage() {
      let limit_ids = {
        open_id: localStorage.getItem("code"),
      };
      get_limit(limit_ids)
        .then((res) => {
          console.log(res);
          this.usages = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleRemove(file, fileList) {
      // console.log(file, fileList);
    },
    handlePreview(file) {
      // console.log(file);
    },
    // 上传前处理
    beforeUpload(file) {
      // let fileSize = file.size;
      // const FIVE_M = 5 * 1024 * 1024;
      // //大于5M，不允许上传
      // if (fileSize > FIVE_M) {
      //   this.$message.error("最大上传5M");
      //   return false;
      // }
      //判断文件类型，必须是xlsx格式
      // let fileName = file.name;
      // let reg = /^.+(\.xlsx)$/;
      // if (!reg.test(fileName)) {
      //   this.$message.error("只能上传xlsx!");
      //   return false;
      // }
      // return true;
    },
    on_change(file, fileList) {
      console.log(file, fileList);
      let fileName = file.name;
      let reg = /^.+(\.doc)$/;
      let reg01 = /^.+(\.docx)$/;
      let reg02 = /^.+(\.txt)$/;
      if (
        !reg.test(fileName) &&
        !reg01.test(fileName) &&
        !reg02.test(fileName)
      ) {
        this.$message.error("只能上传doc , docx  , txt 格式文件!");
        this.fileList = [];
        return false;
      } else {
        this.ruleForm.file = file;
        this.fileList = fileList;
      }
    },

    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    reloadProof() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
    // 确认上传
    submitForm(formName) {
      this.returns = true;

      // console.log("aa")
      // var formData = new FormData($("#uploadForm")[0]);
      // var formData = new FormData($("#uploadForm")[0]);
      // console.log(formData)
      // this.$refs.uploadFile2是vue中获取dom元素的方法
      // 通过files就可以拿到所有上传的文件，如果是多个文件循环即可
      // formData.append("file", this.$refs.uploadFile2.files[0]);
      // 必须设置请求类型
      // formData.append("title", this.ruleForm.title);
      // 如果需要传id的情况下，参考下面代码
      // formData.append("author", this.ruleForm.author);

      if (this.fileList.length > 0) {
        var formData = new FormData();
        formData.append("input_file", this.fileList[0].raw);
      } else {
        var formData = new FormData($("#uploadForm")[0]);
      }

      //   formData.append("is_tran", 0);
      formData.append("open_id", localStorage.getItem("code"));
      formData.append("organ_name", localStorage.getItem("org_name"));
      // console.log(this.ruleForm);
      // checks(formData)
      //   .then((res) => {
      //     console.log(res);
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
      // var formData = this.ruleForm;

      $.ajax({
        url: "https://scholary.newacademic.net/api/qingli/writ/input_file",
        type: "POST",
        cache: false,
        data: formData,
        processData: false,
        contentType: false,
        headers: {
          // "Content-Type": "application/x-www-form-urlencoded",
          token: localStorage.getItem("token"),
          // Token:"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2NDE5NzQ4OTAsImlhdCI6MTY0MTM3MDA4MCwiZGF0YSI6eyJpZCI6MTY0LCJlbWFpbCI6IjEwMTM3MTU0NzNAcXEuY29tIiwicGhvbmUiOiIxODR4eHh4MDYwNyIsIm5hbWUiOiJcdTVmOTBcdTU2MDlcdTY1NGYiLCJmYWN1bHR5IjoiXHU1ZjAwXHU1M2QxIiwic3RhdHVzIjowfX0.629NEWBqMr2-unocUDSScxUQAhr0G3HgitzdnM4hw7E",
        },
      })
        .done(function (res) {
          console.log(res);
          let that = this;
          that.fileList == [];
          if (res.code == 200) {
            Message({
              message: res.message + ",请在  查看报告  中浏览",
              type: "success",
            });
          } else {
            Message.error(res.message);
            this.returns = false;
          }
          // this.returns = false;
        })
        .fail(function (res) {
          // Message.error("提交失败！！请稍后再试！");
          Message.error(res.message);
          this.returns = false;
          // this.returns = false;
        });
      //   this.reloadProof();
      setTimeout(() => {
        this.returns = false;
      }, 1000);
      // this.$refs[formName].validate((valid) => {
      //   if (valid) {
      //     // console.log(this.ruleForm);

      //   } else {
      //     console.log("error submit!!");
      //     return false;
      //   }
      // });
    },
  },
  components: {
    LEFTNAV,
  },
};
</script>

<style lang="less" scoped>
.upload_box {
  padding-top: 75px;
  min-width: 1360px;
  display: flex;

  color: #000000;
  .box {
    margin: 20px;
    background: white;
    width: 100%;
    .box_part {
      //   background: white;
      width: 100%;
      padding: 20px;
    }
  }
}
.upLoad {
  width: 90%;
  max-width: 1200px;
  margin: auto;
  .el-input {
    width: 360px;
  }
  .btn {
    width: 100%;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    .el-button {
      width: 120px;
      font-size: 16px;
    }
  }
  .upDemo {
    width: calc(100% - 100px);
    // width: 500px;
    // margin: auto;
    min-width: 920px;
    height: 300px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 45px;
    border: 1px rgb(139, 139, 139) dashed;
    border-radius: 5px;
    .upload-demo {
      // width: 920px;
      // margin: 0 40px;
      /deep/ .el-upload {
        // width: 100%;
        .el-upload-dragger {
          // width: 100%;
          // height: 300px;
          border: none;
          .upStyle {
            // display: flex;
            // justify-content: space-between;
          }
        }
      }
    }
  }
}
.prompts {
  display: flex;
  justify-content: center;
}
.prompt {
  // min-width: 1100px;
  width: 90%;
  max-width: 1200px;
  // margin: 0 20%;
  .el-steps {
    margin: 0px 50px;
    color: goldenrod !important;
    /deep/ .el-step__head {
      color: #409eff;
      border-color: #409eff;
    }
    /deep/ .el-step__title {
      color: #409eff;
      font-size: 20px;
      position: relative;
      left: -20px;
    }
    /deep/ .el-step__icon-inner {
      font-size: 30px;
      margin: 0 5px;
    }
  }
  .prompt_text {
    margin: 5px 50px;
    display: flex;
    .arrow {
      display: inline-block;
      width: 80px;
      height: 40px;
      line-height: 32px;
      text-align: center;
      border: 3px solid rgb(207, 207, 207);
      border-right: none;
      color: rgb(107, 107, 107);
      font-size: 18px;
    }
    i {
      display: inline-block;
      font-size: 55px;
      position: relative;
      top: -7px;
      left: -20px;
      font-weight: lighter;
      color: rgb(207, 207, 207);
    }
    .text {
      line-height: 40px;
      display: inline-block;
      font-size: 17px;
    }
  }
}
.note_fu {
  display: flex;
  justify-content: center;
  margin: 20px 110px 20px 80px;
  .note_fu01 {
    width: 100%;
    // max-width: 1070px;
    // margin: 30px auto !important;
  }
}
/deep/ .el-divider {
  // min-width: 800px;
  margin: 0 !important;
  //   width: 90% !important;
}
.note {
  //   width: 90%;
  //    min-width: 800px;
  padding: 10px 30px;
  border-left: 1px #dcdfe6 solid;
  border-right: 1px #dcdfe6 solid;
  border-bottom: 1px #dcdfe6 solid;
  p {
    line-height: 50px;
    // min-width: 1000px;
    img {
      margin: 0 20px;
    }
  }
}
.upload_tip {
  position: relative;
  margin: 50px 0 30px 0;
  background-color: #f0f7fd;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;

  .upload_tip_img {
    position: absolute;
    left: 50%;
    top: -50px;
    width: 100px;
    height: 100px;
    transform: translateX(-50%);
  }
  .upload_tip_title {
    font-size: 13px;
    color: red;
  }
  .upload_tip_text {
    margin-top: 16px;
    padding: 20px;
  }
  .upload_tip_p {
    margin-bottom: 4px;
    line-height: 1.5;
    color: #777;
  }
  .upload_tip_p::before {
    content: "";
    display: inline-block;
    width: 5px;
    height: 5px;
    margin-right: 10px;
    border-radius: 50%;
    vertical-align: middle;
    background-color: #666;
  }
}
/deep/ .el-upload-list {
  position: relative;
  top: -30px;
}
.usage {
  margin: 10px 50px 30px 50px;
  font-size: 18px;
  // color: rgb(138, 71, 247);
}
</style>